// Include any default variable overrides here (though functions won't be available)
$primary: #333;
$secondary: #da291c;
// $secondary: rgb(13, 71, 161);
// $secondary: #795548;
$gray: rgb(178, 186, 194);

$spacer: 2rem;

@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../_includes/partials/components/scrollToTop/scrollToTop.scss";
@import "../../_includes/partials/components/loadingBackdrop/loadingBackdrop.scss";
@import "../../_includes/partials/components/message/message.scss";
@import "./gilroy-font.scss";
@import "./home.scss";

// Then add additional custom code here

body {
    font-family: 'Gilroy', 'Roboto', sans-serif;
}

.scrolled-down{
    transform:translateY(-100%); transition: all 0.3s ease-in-out;
}
.scrolled-up{
    transform:translateY(0); transition: all 0.3s ease-in-out;
}

.text-gray {
    color: $gray !important;
}

.text-bg-green {
    color: white !important;
    background: hsl(141, 53%, 53%) !important;
}

.text-bg-red {
    color: white !important;
    background: #da291c !important;
}

section {
    padding-top: 32px;
    padding-bottom: 32px;     
}