#home-banner-container.row {
    display: relative;
    background: linear-gradient(90deg, #333, #111);
    overflow-x: hidden;

    #home-banner-left,
    #home-banner-right {
        height: calc(100vh - 73px);
        background-size:cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    #home-banner-left {
        background-image: url(../img/billy-freeman-bAsxH3pWW8I-unsplash.jpg);
        background-position: 25% center;
        transform: skewX(11deg) translateX(1px);
    }    
    
    #home-banner-right {
        background-image: url(../img/tobias-van-schneider-lHGeqh3XhRY-unsplash.jpg);
        transform: skewX(11deg);
    }
}

.logo-navbar {
    color: #fff;
    text-shadow: -1px 0 $secondary, 0 1px $secondary, 1px 0 $secondary, 0 -2px $secondary;
    letter-spacing: 4;
    line-height: 1.25;
    font-size: 2em;
    font-weight: bold;
}

.logo-navbar-mobile {		
    color: #fff;
    text-shadow: -1px 0 $secondary, 0 1px $secondary, 1px 0 $secondary, 0 -2px $secondary;
    letter-spacing: 4;
    line-height: 0.9;
    font-size: 1.6em;
    font-weight: bold;
    white-space: pre;
}

.logo-footer {
    color: $secondary;
    text-shadow: -1px 0 #333, 0 1px #333, 1px 0 #333, 0 -2px #333;
    letter-spacing: 4;
    line-height: 1.25;
    font-size: 2em;
    font-weight: bold;
}

.logo-mobile-text-pot {
    font-size: 0.75em;
}

#home-title-container {
    h1 {
        letter-spacing: 4;
        line-height: 1.25;
        text-shadow: 4px 4px 7px #000;
        font-size: 3.5em;
        @include media-breakpoint-up(sm) {
            font-size: 4em;
        }
        @include media-breakpoint-up(md) {
            font-size: 4.5em;
        }
        @include media-breakpoint-up(lg) {
            font-size: 5em;
        }
        @include media-breakpoint-up(xl) {
            font-size: 5.5em;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 6.5em;
        }
    }
}

.title-caret,
.subtitle-caret {
  margin-left: -55px;
  font-weight: bold;
  color: white;
  opacity: 1;
  animation: blink 1s step-end infinite;
  @media screen and (max-width: 992px) {
    margin-left: -30px;
  }
}
.subtitle-caret {
  margin-left: 0px;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}

#home-subtitle-container {
    background: #0009;
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.card-500 {
    height: 500px;
    @media screen and (max-width: 992px) {
        height: auto;
    }
}

.card-600 {
    height: 600px;
    @media screen and (max-width: 992px) {
        height: auto;
    }
}

.card-min-600 {
    min-height: 600px;
    @media screen and (max-width: 992px) {
        min-height: 300px;
    }
}

#services {
    .card {
        min-height: 250px;
    }
}